import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { useLocation } from '@reach/router'
import { Container } from 'react-bootstrap'
import Layout from '~components/Layout'
import SEO from '~components/seo'
import Icon from '~components/Icon'
import Application from '~components/Application'
import cn from 'classnames'
import {
  vacancy,
  vacancy_heading,
  vacancy_inner,
  vacancy_container,
  vacancy_info,
  vacancy_label,
  vacancy_share,
  copied,
  vacancy_share_text,
  vacancy_descr,
  vacancy_title,
} from './style.module.scss'

const Vacancy = ({ data: { publication } }) => {
  const location = useLocation()
  const [share, setShare] = useState(true)
  const handleShare = () => {
    navigator.clipboard.writeText(location.href)
    setShare(false)
    setTimeout(() => {
      setShare(true)
    }, 3000)
  }
  const { header, employment, address, descr } = publication
  return (
    <Layout>
      <Container>
        <SEO title={`${header} – Careers`} />
        <div className={vacancy}>
          <h1 className={vacancy_heading}>{header}</h1>
          <div className={vacancy_inner}>
            <div className={vacancy_container}>
              <div className={vacancy_info}>
                {employment && (
                  <span className={vacancy_label}>
                    <Icon name="case" size={20} />
                    {employment}
                  </span>
                )}
                {address && (
                  <span className={vacancy_label}>
                    <Icon name="mark" size={20} />
                    {address}
                  </span>
                )}
                {share ? (
                  <button onClick={handleShare} className={vacancy_share}>
                    <Icon name="share" size={20} />
                    <span className={vacancy_share_text}>Share this job</span>
                  </button>
                ) : (
                  <div className={cn(vacancy_share, copied)}>
                    <Icon name="check" size={20} />
                    <span className={vacancy_share_text}>Link Copied</span>
                  </div>
                )}
              </div>
              <div className={vacancy_descr}>
                {descr.map(({ title, content }) => (
                  <div key={title}>
                    <p className={vacancy_title}>{title}</p>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                  </div>
                ))}
              </div>
            </div>
            <Application />
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default Vacancy

export const pageQuery = graphql`
  query careersQuery($slug: String!) {
    publication: careersJson(slug: { eq: $slug }) {
      header
      employment
      address
      descr {
        title
        content
      }
    }
  }
`
