// extracted by mini-css-extract-plugin
export const vacancy = "c_c";
export const vacancy_heading = "c_d";
export const vacancy_inner = "c_f";
export const vacancy_container = "c_g";
export const vacancy_info = "c_h";
export const vacancy_label = "c_j";
export const vacancy_share = "c_k";
export const copied = "c_l";
export const vacancy_share_text = "c_m";
export const vacancy_descr = "c_n";
export const vacancy_title = "c_p";